import instance from "../axios";
import * as endpoints from "./endpoints";

import { AUTHORIZATION_KEY } from "global_constants";

export const getSignature = (address) => {
  const payload = { address };
  return instance.post(endpoints.SIGNATURE_API_PATH, payload);
};

export const connect = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.CONNECT_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      instance
        .get(endpoints.USER_API_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const getClaimSignatureData = (data) => {
  return instance.post(endpoints.CLAIM_SIGNATURE_API_PATH, data);
};

export const getReferralTransactions = () => {
  return instance.get(endpoints.REFERRAL_TRANSACTION_API_PATH);
};
