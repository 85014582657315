import axios from "axios";
// import { decrypt, encrypt } from "utils/crypto";

let headers = {
  "Content-Type": "application/json",
};

const axiosInstance = axios.create({
  headers,
  timeout: 40000,
  baseURL: `${process.env.REACT_APP_API_HOST}/api/v1/`,
  // transformResponse: (response) => {
  //   return decrypt(response);
  // },
  // transformRequest: [
  //   (data, headers) => {
  //     if (data) {
  //       return encrypt(data);
  //     }
  //   },
  //   ...axios.defaults.transformRequest,
  // ],
});

export default axiosInstance;
