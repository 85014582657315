import React from "react";

import NotFoundPage from "pages/404Page";
import RenderRoutes from "components/renderRoutes";

export const INDEX_ROUTE = "/";

const LayoutComponent = React.lazy(() => import("layout"));
const DashboardComponent = React.lazy(() => import("pages/dashboard"));

const ConnectComponent = React.lazy(() => import("pages/connect"));

export const NON_LOGIN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: "connect",
        path: INDEX_ROUTE,
        component: ConnectComponent,
      },
    ],
  },
];

export const LOGGED_IN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: LayoutComponent,
    routes: [
      {
        exact: true,
        name: "dashboard",
        path: INDEX_ROUTE,
        component: DashboardComponent,
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];
