export const AUTHORIZATION_KEY = "viewers_affiliate_token";

export const NETWORK = {
  TARGET_CHAIN_ID: 97,
  MAINNET_CHAIN_ID: 1,
  CHAIN_ID_TO_NAME: {
    1: "mainnet",
    3: "ropsten",
    4: "rinkeby",
    42: "kovan",
    1337: "localhost",
    97: "bsctestnet",
  },
  INFURA_ID: "632b4850f79f4f7f9ba2468897bdcbc0",
};

export const DEFAULT_FORM_VALIDATION_MESSAGES = {
  email: "Enter a valid email",
  required: "${label} is required!",
};

export const CONTRACTS = {
  SERVERBRIDGE: "ViewsServerBridge",
};
