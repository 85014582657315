import { useState } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";

import { NETWORK } from "global_constants";
import { getProviders } from "utils/providers";

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions: getProviders(),
  disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});

export default function useConnect(existingProvider) {
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [provider, setProvider] = useState(existingProvider);

  async function initiateWeb3(proxy) {
    let response = null;
    const instance = new ethers.providers.Web3Provider(proxy);

    const signer = await instance.getSigner();
    const address = await signer.getAddress();
    const { chainId } = await instance.getNetwork();

    if (chainId === NETWORK.TARGET_CHAIN_ID) {
      setProvider({
        instance,
        signer,
        address,
        chainId,
      });
      response = {
        instance,
        signer,
        address,
        chainId,
      };
      setIsConnected(true);
    } else {
      await web3Modal.clearCachedProvider();
    }
    setIsConnecting(false);
    return response;
  }

  async function doAutoConnect() {
    let response = null;
    const { cachedProvider } = web3Modal;
    const cachedFromStorage = JSON.parse(
      localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")
    );

    if (cachedProvider && cachedProvider !== "") {
      response = initiateWeb3(await web3Modal.connectTo(cachedProvider));
    } else if (cachedFromStorage && cachedFromStorage !== "") {
      response = initiateWeb3(await web3Modal.connectTo(cachedFromStorage));
    }
    return response;
  }

  async function doDisconnect() {
    await web3Modal.clearCachedProvider();
    setProvider();
  }

  function doConnect() {
    setIsConnecting(true);

    web3Modal
      .connect()
      .then((proxy) => {
        initiateWeb3(proxy);
      })
      .catch((e) => {
        setIsConnecting(false);
      });
  }

  return [
    isConnected,
    isConnecting,
    doConnect,
    provider,
    doAutoConnect,
    doDisconnect,
  ];
}
