import { useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";

import { isEmpty } from "_dash";
import { getUserDetail } from "actions";
import { AUTHORIZATION_KEY } from "global_constants";
import { LOGGED_IN_ROUTES, NON_LOGIN_ROUTES } from "routes";

import RouteWithSubRoutes from "./components/routeWithSubRoutes";

import AccountHOC from "hoc/account";
import ProviderHOC from "hoc/provider";
import useConnect from "hooks/useConnect";

function App({ provider, accountData, setAccountData, setProvider }) {
  const isLoggedIn = !isEmpty(accountData);

  const [loading, setLoading] = useState(true);
  const [appRoutes, setAppRoutes] = useState([]);
  const [, , , connection, doAutoConnect, ,] = useConnect(provider);

  useEffect(() => {
    if (connection) {
      setProvider(connection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  useEffect(() => {
    getUserDetail()
      .then(async (response) => {
        const connectResponse = await doAutoConnect();
        if (
          !connectResponse ||
          (connectResponse && connectResponse.address !== response.address)
        ) {
          localStorage.removeItem(AUTHORIZATION_KEY);
        } else {
          setAccountData(response);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading) {
      if (isLoggedIn) {
        setAppRoutes(LOGGED_IN_ROUTES);
      } else {
        setAppRoutes(NON_LOGIN_ROUTES);
      }
    }
  }, [isLoggedIn, loading]);

  return (
    <div className="container">
      <BrowserRouter>
        <Switch>
          {appRoutes.map((route, i) => {
            return <RouteWithSubRoutes key={i} {...route} />;
          })}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default ProviderHOC(AccountHOC(App));
